<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("correlation-create.header") }}
      </h3>
      <CorrelationForm :correlation="correlation" :onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import InitCorrelationCreateInteractor from "@/business/correlation-create/init-correlation-create";
import SubmitFormInteractor from "@/business/correlation-create/submit-form";
import CorrelationCreateScreenController from "@/adapters/controllers/screen-correlation-create";
import CorrelationForm from "@/application/components/correlations/CorrelationForm";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "correlation-create",
  components: { CorrelationForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initCorrelationCreate: null,
        submitForm: null
      },
      isLoading: false,
      data: null,
      correlation: {
        name: "",
        algorithm: 1,
        algorithmValue: 25,
        controlGroupType: 1
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(CorrelationCreateScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initCorrelationCreate = this.$injector.get(
      InitCorrelationCreateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initCorrelationCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      // console.log(this.correlation);
      this.interactors.submitForm.handle(this.correlation);
    }
  }
};
</script>

<style lang="scss"></style>
